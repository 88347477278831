<template>
  <article class="contact-us" :class="{ mobile: isMobile }">
    <img
      class="banner"
      src="@/assets/images/Global/contact_us_banner.png"
      alt="Electrify America chargers under a lighted canopy at night"
    />
    <div class="article-body text">
      <div class="container" data-aos="fade-up">
        <form id="contact-form" @submit.prevent="validate" ref="form">
          <h1 class="header">Let’s power excellence together</h1>
          <p class="detail">
            The road to your electrified future begins now. Help us get to know you (and your project), and we’ll be in
            touch to discuss customized approaches to tackling your clients’ electrification needs.
          </p>
          <div class="form-wrap" :class="{ mobile: isMobile }">
            <div class="input-field">
              <label ref="firstname" for="first_name"><span class="required">*</span>First Name</label>
              <input id="first_name" maxlength="39" name="first_name" v-model="firstName" type="text" required />
            </div>
            <div class="input-field">
              <label ref="lastname" for="last_name"><span class="required">*</span>Last Name</label>
              <input
                id="last_name"
                maxlength="39"
                name="last_name"
                type="text"
                pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
                title="This field only accepts letters and special characters (no trailing spaces)"
                v-model="lastName"
                required
              />
            </div>
            <div class="input-field">
              <label ref="email" for="email"><span class="required">*</span>Business Email</label>
              <input
                id="email"
                maxlength="80"
                name="email"
                type="text"
                v-model="email"
                pattern="[a-zA-Z0-9_@./#&+-]+(?:\.[a-zA-Z0-9_@./#&+-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
                title="Please enter the correct email address"
                required
              />
            </div>
            <div class="input-field">
              <label ref="companyname" for="company_name"><span class="required">*</span>Company Name</label>
              <input id="company_name" maxlength="200" name="company_name" type="text" v-model="companyName" required />
            </div>
            <div class="input-field">
              <label ref="title" for="title"><span class="required">*</span>Job Title</label>
              <input id="title" maxlength="128" name="title" type="text" v-model="title" required />
            </div>
            <div class="input-field">
              <label for="industry_type"><span class="required">*</span>Industry Type</label>
              <select required v-model="industryType" name="industry_type" id="industry_type">
                <option v-for="(item, index) in industryTypeOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>
            <div class="input-field">
              <label for="phone"><span class="required">*</span>Business Phone</label>
              <input
                id="phone"
                maxlength="10"
                name="phone"
                v-model="phoneNumber"
                type="text"
                pattern="^\d+$"
                title="This field only accepts numbers"
                required
              />
            </div>
            <div class="input-field">
              <label for="country">Country</label>
              <select v-model="country" name="country" id="country">
                <option v-for="(item, index) in countryOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>
            <div class="input-field">
              <label for="zipCode">Zip/Postal Code</label>
              <input
                id="zipCode"
                maxlength="10"
                name="zipCode"
                v-model="zipCode"
                type="text"
                pattern="^[a-zA-Z0-9\s]+(?:[a-zA-Z0-9-\s])+(?:[a-zA-Z0-9\s])$"
                title="This field only accepts numbers"
              />
            </div>
            <div class="input-field">
              <label for="product_type">Interested Product</label>
              <select v-model="interestedProduct" name="product_type" id="product_type">
                <option v-for="(item, index) in interestedProductOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>
            <div class="input-field">
              <label for="use_case"><span class="required">*</span>Use Case</label>
              <select required v-model="useCase" name="use_case" id="use_case">
                <option v-for="(item, index) in useCaseOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>
            <div class="input-field">
              <label for="timeline"><span class="required">*</span>Desired Timeline</label>
              <select required v-model="timeline" name="timeline" id="timeline">
                <option v-for="(item, index) in timelineOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>
            <div class="input-field">
              <textarea placeholder="Notes" v-model="notes" maxlength="1000" id="notes" name="notes"></textarea>
            </div>
            <div class="input-field checkbox">
              <label for="hostStation">
                <input v-model="hostStation" id="hostStation" name="hostStation" type="checkbox" value="true" />
                <p class="form-question">
                  Are you looking to host an Electrify America charging station, provide general feedback, or become a
                  vendor?
                  <span v-show="hostStation"
                    >Thank you for your interest! Please click
                    <a rel="noopener" target="_blank" href="https://www.electrifyamerica.com/submissions/">here</a> to
                    submit your request.</span
                  >
                </p>
              </label>
            </div>
            <div class="input-field checkbox">
              <label for="future-comm">
                <input v-model="OptMeinFutureComm" id="future-comm" name="future-comm" type="checkbox" value="true" />
                <p class="form-question">
                  Send me news and marketing offers from Electrify America via telephone calls and text messages at the
                  phone number provided. I understand such calls and texts may be made using an automated dialing system
                  and that my consent to receive such calls and texts is not required as a condition of making a
                  purchase.
                  <span
                    >Click <a href="https://www.electrifyamerica.com/privacy/" class="link">here</a> for Electrify
                    America's Privacy Statement.</span
                  >
                </p>
              </label>
            </div>
            <input ref="submitButton" class="button" name="Sign up" type="submit" value="SUBMIT" />
          </div>
          <p v-show="serverError !== ''" class="server-error">
            Error:
            <br />
            {{ serverError }}
          </p>
        </form>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'contactus',
  metaInfo: {
    title: 'Contact us | Electrify Commercial',
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: 'Electrify Commercial',
          url: 'https://www.electrify-commercial.com/',
          sameAs: [
            'https://twitter.com/electrifyam',
            'https://www.facebook.com/ElectrifyAmerica/',
            'https://www.instagram.com/electrifyamerica/?hl=en',
            'https://www.linkedin.com/company/electrifyamerica/about/',
          ],
          logo: 'https://twitter.com/electrifyam/photo',
          parentOrganization: {
            '@type': 'Organization',
            name: 'Electrify Commercial',
            url: 'https://www.electrify-commercial.com/',
          },
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+1-833-632-2778',
              contactType: 'customer support',
              contactOption: 'TollFree',
              areaServed: 'US',
            },
          ],
        },
      },
    ],
    meta: [
      {
        name: 'description',
        content: `Let's get to work. Electrify Commercial wants to hear from you. Fill out the form and we’ll get back to you soon.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/contact-us/' }],
    noscript: [
      {
        innerHTML:
          '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KXKDRHT" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
        pbody: true,
      },
    ],
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      companyName: null,
      title: null,
      industryTypeOptions: [
        'Government',
        'Utility',
        'Fleet Owner/Operator',
        'Automotive Manufacturer',
        'Hotel',
        'Dealership',
        'Property Developer',
        'Retail',
        'Commerical Office',
        'Vendor',
        'Fueling Center',
        'Consultant',
        'Other',
      ],
      industryType: '',
      phoneNumber: null,
      countryOptions: ['United States', 'Canada'],
      country: 'United States',
      zipCode: null,
      interestedProductOptions: ['DC Fast Charging (25kW, 50kW, 100kW, 150kW+)', 'Uncertain'],
      interestedProduct: '',
      useCaseOptions: [
        'Multi-Unit Dwelling',
        'Residential',
        'Workplace',
        'Truck Charging',
        'Bus Charging',
        'Dealership',
        'Fleet Owner/Operator',
        'Own a Public Fast Charging Network',
        'Corporate Program',
        'Non-Road Vehicle',
        'Other',
      ],
      useCase: '',
      timelineOptions: ['Next 6 Months', 'Next Year', '2+ Years', 'Unknown'],
      timeline: '',
      notes: null,
      hostStation: null,
      OptMeinFutureComm: null,
      serverError: '',
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
  },
  methods: {
    async validate(e) {
      e.preventDefault();
      this.$refs.submitButton.disabled = true;
      this.serverError = '';
      try {
        const params = {
          site: 'ELAM',
          recaptchaToken: await this.recaptcha(),
          body: {
            SuppliedFirstName: this.firstName,
            SuppliedLastName: this.lastName,
            SuppliedEmail: this.email,
            SuppliedCompanyName: this.companyName,
            Title: this.title,
            IndustryType: this.industryType,
            SuppliedPhone: this.phoneNumber,
            Country: this.country,
            Zipcode: this.zipCode,
            ProductType: this.interestedProduct,
            UseCase: this.useCase,
            ProjectTimeline: this.timeline,
            Notes: this.notes,
            OptMeinFutureComm: this.OptMeinFutureComm,
            utm_medium: this.$route.query.utm_medium,
            utm_source: this.$route.query.utm_source,
          },
        };
        this.contactusPut(params);
      } catch (error) {
        console.error(error);
        this.$refs.submitButton.disabled = false;
      }
    },

    contactusPut(params) {
      const loader = this.$loading.show();
      if (this.$route.query.source === 'own-a-network') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'own_a_network_form_submit',
          referrer: 'https://www.electrifyamerica.com/',
          'referrer path': 'https://www.electrifyamerica.com/business-solutions/',
        });
      } else if (this.$route.query.source === 'fleet-charging') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'fleet_charging_solutions_form_submit',
          referrer: 'https://www.electrifyamerica.com/',
          'referrer path': 'https://www.electrifyamerica.com/business-solutions/',
        });
      }
      this.$store.commit('setEmail', params.body.SuppliedEmail);
      this.$store
        .dispatch('putContactUs', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'email-verification-contact-us-en' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          loader.hide();
          console.error(error);
        });
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
    /* eslint-disable */
    if (!window.PRERENDER_INJECTED) {
      console.log('applesauce');
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-KXKDRHT');
    }

    if (this.$route.query.source === 'own-a-network') {
      this.useCase = 'Own a Public Fast Charging Network';
    }
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style lang="scss">
.contact-us {
  padding-top: 0 !important;
  .banner {
    width: 100vw;
    display: block;
    margin-bottom: 50px;
  }
  a {
    font-weight: 500;
  }
  p {
    font-size: 18px;

    span {
      font-size: 13px;
    }
  }

  position: relative;
  .header {
    text-align: initial;
    margin: 0 0 16px 0 !important;
    font-weight: bold;
    letter-spacing: 0.06px;
    line-height: 55px !important;
    font-size: 40px !important;
  }

  .email-link {
    text-decoration: underline;
  }

  .detail {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
    letter-spacing: 0.03px;
  }

  .bg {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .container {
    padding: 0 20px !important;
    margin: 0 auto 0 auto;
    max-width: 1200px !important;
    height: 100%;
    @media only screen and (min-width: 750px) {
      padding: 0 40px !important;
    }
  }

  form {
    max-width: 450px;
    width: 100%;
    margin-left: 100px;

    .form-question {
      color: #000000;
      font-weight: normal;
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }

    .required {
      color: red;
    }

    .required-dropdown {
      position: absolute;
      top: -10px;
      left: -10px;
    }

    h2,
    p {
      color: #011352;
    }

    .input-field {
      margin-bottom: 20px;
      position: relative;
    }

    select {
      background: url('../../assets/images/ContactUs/dropdown_arrow.png');
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      background-repeat: no-repeat;
      background-size: 15px;
      margin-top: 2px;
      background-position: right 15px center;
      border: 1px solid #011352;
      border-radius: 8px;
      padding: 0 8px;
      height: 45px;
      color: #011352;

      span {
        color: red !important;
      }
    }

    label {
      font-size: 16px;
      color: #000000;
      font-weight: normal;
      letter-spacing: 0.03px;
    }

    ::placeholder {
      color: #011352;
      font-family: 'Ubuntu', Arial, sans-serif;
    }

    textarea {
      margin-top: 40px;
      border: 1px solid #011352;
      height: 120px;
      padding: 11px;
    }

    input[type='text'] {
      -webkit-appearance: none !important;
      border-radius: 0 !important;
    }

    input {
      margin-bottom: 10px !important;
      margin-bottom: 8px;
      -webkit-box-shadow: inset 0 -1px 0 0 #011352;
      box-shadow: inset 0 -1px 0 0 #011352;

      &:focus {
        outline: none;
        -webkit-box-shadow: inset 0 -1px 0 0 #bfc4d4;
        box-shadow: inset 0 -1px 0 0 #bfc4d4;
      }
    }

    input,
    textarea,
    select {
      transition: 500ms ease;
      box-sizing: border-box;
      max-width: 450px;
      width: 100%;
    }

    textarea,
    select {
      outline: none;
      &:focus {
        border: 1px solid #bfc4d4;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    input[type='submit'] {
      width: 150px;
      padding: 22px 30px;
    }

    .disclaimer {
      font-size: 12px;
      line-height: 1.2 !important;
    }
  }

  .checkbox {
    label {
      display: flex;
    }
    p {
      flex: 1;
      margin: 0 !important;
      line-height: 1 !important;
    }
    input {
      width: fit-content;
      margin-bottom: 8px;
      -webkit-box-shadow: none;
      box-shadow: none;

      &:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
    span {
      margin-top: 8px;
      display: block;
      font-size: 18px;
    }

    .link {
      cursor: pointer;
      text-decoration: underline !important;
    }

    a {
      text-decoration: underline;
    }
  }
}

.contact-us.mobile {
  padding-top: 0px !important;
  form {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  input,
  textarea,
  select {
    max-width: 100%;
    width: 100%;
  }

  .checkbox input {
    width: 25px;
    height: 25px;
  }
}
</style>
