<template>
  <footer class="footer">
    <nav role="navigation">
      <ul class="footer__top-section">
        <li class="footer__col footer__col--logos">
          <ul>
            <li>
              <router-link :to="{ name: `home-${lang}` }" class="footer__logo" :aria-label="homepageAriaLabel">
                <img :src="logoSrc" alt="" class="footer__logo" />
              </router-link>
            </li>
            <li v-if="$slots.logo">
              <slot name="logo"></slot>
            </li>
          </ul>
        </li>
        <li class="footer__links-container">
          <dl class="footer__col" v-for="(links, index) of footerLinks" :key="index">
            <component
              v-for="(link, index2) of links"
              :key="index2"
              :is="link.callout ? 'dt' : 'dd'"
              :class="([link.className], { 'has-spacer': link.spacer })"
            >
              <a v-if="link.src" :href="link.route" rel="noopener" target="_blank">
                <img loading="lazy" :src="link.src" :alt="link.alt" />
              </a>
              <a
                v-else-if="link.external"
                :href="link.route"
                rel="noopener"
                target="_blank"
                :class="{ 'is-callout': link.callout }"
                >{{ link.name }}</a
              >
              <router-link v-else :to="{ name: `${link.route}-${lang}` }" :class="{ 'is-callout': link.callout }">{{
                link.name
              }}</router-link>
            </component>
          </dl>
        </li>
      </ul>
      <ul class="footer__row footer__middle-section">
        <li v-if="mobileAppLinks">
          <ul class="footer__row-links footer__row-links--app">
            <li v-for="(link, index) of mobileAppLinks" :key="index">
              <a rel="noopener" target="_blank" :href="link.route">
                <img loading="lazy" :alt="link.alt" :src="link.src" />
              </a>
            </li>
          </ul>
        </li>
        <li v-if="socialMediaLinks">
          <ul class="footer__row-links footer__row-links--social">
            <li v-for="(link, index) of socialMediaLinks" :key="index">
              <a :href="link.route" rel="noopener" target="_blank">
                <img loading="lazy" :src="link.src" :alt="link.alt" />
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="footer__row footer__row--bottom-links">
        <li>
          <span>&copy; {{ getYear }} {{ site }}</span>
        </li>
        <li v-for="(link, index) of legalLinks" :key="index">
          <a v-if="link.external" :href="link.linkTo" rel="noopener" target="_blank">{{ link.name }}</a>
          <p v-else-if="link.support">
            {{ link.name }}:
            <a :href="`tel:${link.route}`" rel="noopener" target="_blank">
              <strong>{{ link.route }}</strong>
            </a>
          </p>
          <button v-else-if="link.button" :class="[link.class, 'footer__link-button']">
            {{ link.name }} <img loading="lazy" v-if="link.src" :src="link.src" :alt="link.alt" />
          </button>
          <router-link v-else-if="link.hash" :to="{ name: `${link.route}-${lang}`, hash: `${link.hash}` }">{{
            link.name
          }}</router-link>
          <router-link v-else :to="{ name: `${link.route}-${lang}` }">{{ link.name }}</router-link>
        </li>
      </ul>
    </nav>
    <Chat v-if="this.$route.name !== 'mobile-tou'"></Chat>
  </footer>
</template>

<script>
import Chat from '@/components/Chat.vue';

export default {
  name: 'Footer',
  components: { Chat },
  props: {
    socialMediaLinks: { type: Array, required: true },
    footerLinks: { type: Array, required: true },
    legalLinks: { type: Array, required: true },
    mobileAppLinks: { type: Array, required: false },
    homepageAriaLabel: { type: String, required: true },
    logoSrc: { type: String, required: true },
    site: { type: String, required: true },
    lang: { type: String, required: true },
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  background-color: $c-primary;
  color: $c-primary-background;
  padding: 40px 32px 36px 32px;

  @media (min-width: 750px) {
    padding: 90px 48px 24px;
  }
  @media (min-width: 1025px) {
    padding: 90px 48px 65px;
  }
  &__link-button {
    background: transparent;
    color: $c-primary-background;
    border: none;
    font-size: 14px;
    padding: 0;
    font-weight: 300;
  }
  &__logo {
    height: 77px;
    width: auto;
  }

  &__col {
    grid-column-start: auto;
    padding-left: 0;

    router-link.is-callout,
    a.is-callout {
      font-weight: 600;
    }

    li.has-spacer,
    dd.has-spacer,
    dt.has-spacer {
      padding-bottom: 35px;
    }

    a,
    router-link,
    p {
      text-transform: uppercase;
    }

    dd,
    dt {
      margin-left: 0;
      margin-bottom: 0;
    }

    li,
    dd,
    dt {
      line-height: 1.7;
    }

    &--logos {
      width: 100%;
      padding-bottom: 20px;
      margin-right: 64px;

      :first-child {
        padding-right: calc(50% - 120px);
      }
      @media (min-width: 1000px) {
        width: 15%;
        padding-right: 0;
        padding-bottom: 0;
      }
    }
  }

  &__links-container {
    display: grid;
    max-width: 1012px;
    justify-content: space-between;
    grid-template-columns: repeat(1, 1fr);
    gap: 1em 1em;
    @media (min-width: 750px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1124px) {
      grid-template-columns: repeat(5, auto);
      column-gap: 45px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 36px;
    @media (min-width: 1025px) {
      flex-direction: row;
      max-width: 1287px;
      margin-top: 0;
    }
    &--bottom-links {
      margin-bottom: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      @media (min-width: 750px) {
        justify-content: space-between;
      }

      li {
        padding-left: 1.5em;
      }

      li:first-of-type {
        width: 100%;
        text-align: center;
        margin-bottom: 4px;
        @media (min-width: 750px) {
          width: auto;
          margin-bottom: 0;
        }
      }
    }
  }

  &__row-links {
    display: flex;

    &--app {
      margin-bottom: 24px;
      :first-child {
        margin-right: 10px;
      }
      img {
        height: 36px;
        width: auto;
      }
    }
    &--social {
      @media (min-width: 1025px) {
        padding-right: 45px;
      }
      img {
        height: 21px;
        width: auto;
      }
      a {
        padding-right: 18px;
      }
    }
  }

  &__top-section {
    display: flex;
    max-width: 1287px;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 42px;
  }

  &__middle-section {
    justify-content: center;
  }
  a,
  router-link,
  p,
  span {
    color: $c-primary-background;
    margin: 0;
    font-size: 14px;
    letter-spacing: 0.28px;
  }
  dl {
    margin: 0;
  }

  ul {
    padding: 0;
  }
  ul,
  li {
    list-style-type: none;
  }
  &--canada {
    .footer__row--bottom-links {
      li {
        width: auto;
      }
    }
  }
  //specifically for ELCO
  &--commercial {
    .footer__row--bottom-links {
      li {
        width: auto;
      }
    }
    .footer__links-container {
      @media (min-width: 1124px) {
        grid-template-columns: repeat(6, 1fr);
      }
      //social media icons
      nav:nth-child(6) {
        ul {
          display: flex;
        }
        img {
          height: 21px;
          width: auto;
        }
        a {
          padding-right: 18px;
        }
      }
    }
    .footer__col--logos {
      @media (min-width: 750px) {
        width: 28%;
      }
      @media (min-width: 1024px) {
        width: 14%;
      }
    }
  }
}
</style>
