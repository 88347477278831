<template>
  <div
    id="block-clicktochat-2"
    class="block block-block-content block-block-content2a8fc16e-5666-49b6-bdc8-cf8d7af38d8d"
  >
    <div
      class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
    ></div>
  </div>
</template>

<style type="text/css">
@font-face {
  font-family: 'Omnes Regular';
  font-style: normal;
  font-weight: normal;
  src: url('https://liveagentgk-developer-edition.ap5.force.com/resource/1540193580000/LA_Fonts/omnes-regular-webfont.woff')
    format('woff');
}

@font-face {
  font-family: 'Omnes ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: url('https://liveagentgk-developer-edition.ap5.force.com/resource/1540193580000/LA_Fonts/omnes_extralight-webfont.woff')
    format('woff');
}

@font-face {
  font-family: 'Omnes Light';
  font-style: normal;
  font-weight: normal;
  src: url('https://liveagentgk-developer-edition.ap5.force.com/resource/1540193580000/LA_Fonts/omnes_light-webfont.woff')
    format('woff');
}

@font-face {
  font-family: 'Omnes Medium';
  font-style: normal;
  font-weight: normal;
  word-break: break-all;
  src: url('https://liveagentgk-developer-edition.ap5.force.com/resource/1540193580000/LA_Fonts/omnes_medium-webfont.woff')
    format('woff');
}

@font-face {
  font-family: 'Omnes Semibold';
  font-style: normal;
  font-weight: normal;
  src: url('https://liveagentgk-developer-edition.ap5.force.com/resource/1540193580000/LA_Fonts/omnes-semibold-webfont.woff')
    format('woff');
}
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #011352;
  font-family: 'Salesforce Sans', sans-serif;
  box-shadow: none;

  border: 3px solid skyblue;

  min-width: 15em;
  max-width: 17em;
}

.embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled {
  display: none;
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton {
  background: #011352 !important;
  font-family: 'Salesforce Sans', sans-serif !important;
  box-shadow: none !important;
  min-width: 8em !important;
  max-width: 10em !important;

  background-image: none !important;
}
.embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
  display: none !important;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedText > .message:before {
  content: '\2022';
  color: #58d63a;
  font-size: 60px;
  margin: -26px 0px 0px -24px;
  display: inline-block;
  position: absolute;
}
@font-face {
  font-family: 'Salesforce Sans';
  src: url('https://www.sfdcstatic.com/system/shared/common/assets/fonts/SalesforceSans/SalesforceSans-Regular.woff')
      format('woff'),
    url('https://www.sfdcstatic.com/system/shared/common/assets/fonts/SalesforceSans/SalesforceSans-Regular.ttf')
      format('truetype');
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedText > .message {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 4px 10px !important;
  background-color: #011352 !important;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  font-size: 13px;
  text-decoration: none;
  font-weight: normal;
}

.helpButtonLabel .message {
  margin: 4px 10px !important;
  background-color: #011352 !important;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  font-size: 13px;
  text-decoration: none !important;
  font-weight: normal;
}

.helpButtonLabel .message:before {
  content: '\2022';
  color: #58d63a;
  font-size: 60px;
  margin: -26px 0px 0px -24px;
  display: inline-block;
  position: absolute;
}

.embeddedServiceHelpButton .embeddedServiceIcon {
  display: none !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: none;
}
.helpButtonEnabled:focus .helpButtonLabel {
  text-decoration: none;
}
.embeddedServiceSidebarButton {
  background: #35bdd1;
  font-family: 'Ubuntu', 'Omnes Semibold', sans-serif;
}
.embeddedServiceSidebarButton.uiButton--inverse,
.embeddedServiceSidebarButton.uiButton--inverse:hover,
.embeddedServiceSidebarButton.uiButton--inverse:disabled,
.embeddedServiceSidebarButton.uiButton--inverse:disabled:hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover {
  background: #011352 !important;
  color: #fff !important;
}
.embeddedServiceSidebarButton.uiButton--inverse .label {
  color: #fff;
}
.dialog-button-0 {
  background: #011352 !important;
}
.dialog-button-1.uiButton--inverse {
  background: #35bdd1 !important;
}
.embeddedServiceLiveAgentStateChatHeaderOption .embeddedServiceIcon,
.embeddedServiceLiveAgentStateChatHeader .message {
  display: none !important;
}
.embeddedServiceLiveAgentStateChatHeaderOption,
.embeddedServiceLiveAgentStateChatHeaderOption:hover {
  width: 100px;
  padding: 8px;
  background: #35bdd1;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
}
.embeddedServiceLiveAgentStateChatHeaderOption .optionName {
  margin-top: 0;
}
.chatOptions a.embeddedServiceLiveAgentStateChatHeaderOption:last-child,
a.embeddedServiceLiveAgentStateChatHeaderOption:last-child:hover {
  background: #a6aaa9 !important;
  margin-left: 43px;
}
.embeddedServiceSidebarHeader .headerTextContent {
  font-family: 'Ubuntu', 'Omnes Semibold';
}
.embeddedServiceLiveAgentStateWaitingHeader .waitingGreeting.imageIsSet {
  font-family: 'Ubuntu', 'Omnes Semibold';
}
.embeddedServiceLiveAgentStateChatHeader .chatOptions {
  margin-top: 46px;
}
.embeddedServiceLiveAgentStateChatItem.chasitor .chatContent a {
  word-break: break-all;
}
.embeddedServiceLiveAgentStateChatMessage a {
  word-break: break-all;
}
</style>

<script>
export default {
  methods: {
    initESW(gslbBaseURL) {
      window.embedded_svc.settings.displayHelpButton = true; // Or false
      window.embedded_svc.settings.language = 'en-US'; // For example, enter 'en' or 'en-US'
      window.embedded_svc.settings.widgetFontSize = '16px';
      // window.embedded_svc.settings.prechatBackgroundImgURL = 'https://elam-cms-assets.s3.amazonaws.com/inline-images/Chat_PreChat_Header.jpg';
      // window.embedded_svc.settings.waitingStateBackgroundImgURL = 'https://elam-cms-assets.s3.amazonaws.com/inline-images/Chat_WaitingState.png';
      // window.embedded_svc.settings.avatarImgURL = "LiveAgent"
      window.embedded_svc.settings.defaultMinimizedText =
        '<span class="live-icon">&bull;</span>&nbsp;&nbsp;&nbsp;LIVE CHAT'; // (Defaults to Chat with an Expert)
      // (Defaults to Chat with an Expert)
      window.embedded_svc.settings.defaultMinimizedText = 'LIVE CHAT'; // (Defaults to Chat with an Expert)
      window.embedded_svc.settings.disabledMinimizedText = 'OFFLINE'; // (Defaults to Agent Offline)

      window.embedded_svc.settings.loadingText = 'Loading...';
      // (Defaults to Loading)
      // window.embedded_svc.settings.storageDomain = 'yourdomain.com';
      // (Sets the domain for your deployment so that
      // visitors can navigate subdomains during a chat session)

      // Settings for Live Agent
      // window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
      // };
      // window.embedded_svc.settings.prepopulatedPrechatFields = {};
      // Sets the auto-population of pre-chat form fields
      // window.embedded_svc.settings.fallbackRouting = [];
      // An array of button IDs, user IDs, or userId_buttonId
      // window.embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
      // Settings for Live Agent
      // window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
      // };
      // window.embedded_svc.settings.fallbackRouting = [];
      // An array of button IDs, user IDs, or userId_buttonId
      // window.embedded_svc.settings.offlineSupportMinimizedText = '...'; // (Defaults to Contact Us)
      window.embedded_svc.settings.extraPrechatFormDetails = [
        {
          label: 'Chat Origin',
          value: 'Web Chat - ELAM',
          transcriptFields: ['Chat_Origin__c'],
          displayToAgent: true,
        },
        {
          label: 'First Name',
          transcriptFields: ['First_Name__c'],
        },
        {
          label: 'Last Name',
          transcriptFields: ['Last_Name__c'],
        },
        {
          label: 'Email',
          transcriptFields: ['Email__c'],
        },
      ];

      window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.settings.entryFeature = 'LiveAgent';

      window.embedded_svc.init(
        process.env.VUE_APP_ENV === 'production'
          ? 'https://electrifyamerica.my.salesforce.com'
          : 'https://electrifyamerica--training.sandbox.my.salesforce.com',
        process.env.VUE_APP_ENV === 'production'
          ? 'https://electrifyamerica.secure.force.com'
          : 'https://electrifyamerica--training.sandbox.my.salesforce-sites.com/',
        gslbBaseURL,
        process.env.VUE_APP_ENV === 'production' ? '00D1N000002b5V1' : '00D55000000WjpA',
        'ELAM_Live_Agent',
        {
          baseLiveAgentContentURL:
            process.env.VUE_APP_ENV === 'production'
              ? 'https://c.la4-c4-ph2.salesforceliveagent.com/content'
              : 'https://c.la1-c1cs-ph2.salesforceliveagent.com/content',
          deploymentId: '5721N000000g7dj',
          buttonId: '5731N000000g7Tm',
          baseLiveAgentURL:
            process.env.VUE_APP_ENV === 'production'
              ? 'https://d.la4-c4-ph2.salesforceliveagent.com/chat'
              : 'https://d.la1-c1cs-ph2.salesforceliveagent.com/chat',
          eswLiveAgentDevName:
            process.env.VUE_APP_ENV === 'production'
              ? 'EmbeddedServiceLiveAgent_Parent04I1N000000blQtUAI_169c1f90d88'
              : 'EmbeddedServiceLiveAgent_Parent04I1N000000blQtUAI_169c1f90d88',
          isOfflineSupportEnabled: false,
        }
      );
    },
  },
  mounted() {
    if (!window.PRERENDER_INJECTED && !window.embedded_svc) {
      const s = document.createElement('script');
      s.setAttribute(
        'src',
        process.env.VUE_APP_ENV === 'production'
          ? 'https://electrifyamerica.my.salesforce.com/embeddedservice/5.0/esw.min.js'
          : 'https://electrifyamerica--training.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js'
      );
      s.onload = () => {
        this.initESW(null);
      };
      document.body.appendChild(s);
    } else {
      this.initESW('https://service.force.com');
    }
  },
};
</script>
