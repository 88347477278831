<template>
  <span>
    <button @click="openToast" class="reference-button" :aria-label="buttonLabel">
      <sup class="ref-link">
        {{ num }}
      </sup>
    </button>
    <div v-if="open" class="reference" :class="{ mobile: isMobile }" role="dialog" :aria-label="dialogLabel">
      <button @click="closeToast" class="close" aria-label="close dialog" style="cursor: pointer" ref="close">
        <SearchCloseIcon class="closeIcon" />
      </button>
      <div class="wrapper">
        <small>{{ num }}</small>
        <slot />
      </div>
    </div>
  </span>
</template>

<script>
import SearchCloseIcon from '../assets/images/Global/searchCloseIcon.svg';

export default {
  name: 'Reference-Link',
  components: { SearchCloseIcon },
  props: {
    num: null,
  },
  data() {
    return {
      priorFocus: null,
      open: false,
      buttonLabel: `Disclosure ${this.num}, this opens a modal.`,
      dialogLabel: `Reference ${this.num} modal.`,
      referenceID: Math.random().toString(36).substring(2, 15),
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    currentRefID() {
      return this.$store.state.currentRefID;
    },
  },
  methods: {
    openToast() {
      this.$store.commit('setCurrentRefID', this.referenceID);
      this.open = true;
      this.priorFocus = document.activeElement;
      this.$nextTick(() => {
        const focusClose = this.$refs.close;
        focusClose.focus();
      });
    },
    closeToast() {
      this.open = false;
      this.priorFocus.focus();
    },
  },
  watch: {
    currentRefID(newValue) {
      if (newValue !== this.referenceID) {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #fff;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $c-highlights;
  }
}
.closeIcon {
  width: 20px;
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}
.reference-button {
  height: 15px;
  width: 15px;
  .ref-link {
    cursor: pointer;
    display: inline-block;
    position: relative;
    top: -0.3em;
    text-align: center;
    background-color: #011352;
    border-radius: 50%;
    font-size: 9px;
    color: white;
    height: 15px;
    width: 15px;
    padding: 0;
    font-weight: 600;
    line-height: 1.6em;
  }

  &:hover .ref-link,
  &:focus .ref-link {
    background: $c-highlights;
    color: $c-primary;
  }
}

.home-carousel {
  .reference {
    position: absolute;
  }
}
.reference {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  max-height: 500px;
  background-color: #262626;
  color: white;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-indent: 0;
  font-weight: 400;
  font-size: 1rem;
  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
  &.mobile {
    width: 100%;
    .wrapper {
      padding: 40px 40px 40px 20px;
    }
  }
  &:not(.mobile) {
    width: 80%;
    .wrapper {
      margin: 40px 100px;
    }
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 20px;
  }
  .wrapper {
    small {
      margin-right: 4px;
      position: relative;
      bottom: 5px;
    }
  }
}
</style>
