<template>
  <div id="app">
    <a class="sr-only sr-only-focusable skip-link" href="#main">Skip to content</a>
    <Header />
    <main id="main"><router-view /></main>
    <Footer
      homepage-aria-label="Electrify Commercial"
      :logoSrc="logoSrc"
      site="Electrify Commericial"
      lang="en"
      :footer-links="footerLinks"
      :legal-links="legalLinks"
      :social-media-links="socialMediaLinks"
    />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: { Header, Footer },
  data() {
    return {
      logoSrc: require('@/assets/images/Footer/footerlogowhite_v2.png'),
    };
  },
  methods: {
    pushGAEvent(event, target, category, label) {
      if (target.getAttribute && target.getAttribute('target') === '_blank') {
        this.$gtag.event(label, {
          event_category: category,
          event_label: target.href,
        });
      } else {
        event.preventDefault();
        this.$gtag.event(label, {
          event_category: category,
          event_label: target.href,
          transport_type: 'beacon',
          event_callback: () => {
            document.location = target.href;
          },
        });
      }
    },
    trackLinkClick(event) {
      let { target } = event;
      while (target && target.tagName !== 'A') target = target.parentNode;

      // track PDF links
      if (target && target.matches('a[href$=".pdf"], a[href$=".PDF"]') && target.href) {
        this.pushGAEvent(event, target, 'Downloads', 'PDF');
        return;
      }

      // track only links that reference external resources)
      if (target && target.matches('a[href*="://"]') && target.href) {
        this.pushGAEvent(event, target, 'Outbound links', 'Click');
        return;
      }

      // track mailto links
      if (target && target.matches('a[href^="mailto"')) {
        this.pushGAEvent(event, target, 'Mails', 'Click');
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.trackLinkClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.trackLinkClick);
  },
  computed: {
    footerLinks() {
      return [
        [
          {
            name: 'About',
            route: 'about-us',
            callout: true,
          },
          {
            name: 'Public Charging',
            route: 'https://www.electrifyamerica.com/',
            external: true,
          },
          {
            name: 'Electrify America',
            route: 'https://www.electrifyamerica.com/',
            external: true,
          },
        ],
        [{ name: 'Our Advantage', route: 'advantages', callout: true }],
        [{ name: 'Featured Projects', route: 'feature-01', callout: true }],
        [{ name: 'Nevi Funding', route: 'nevi', callout: true }],
        [{ name: 'Contact Us', route: 'contact-us', callout: true }],
      ];
    },
    legalLinks() {
      return [
        {
          name: 'Privacy',
          linkTo: 'https://www.electrifyamerica.com/privacy/',
          external: true,
        },
        {
          name: 'Your Privacy Choices',
          class: 'optanon-show-settings',
          src: require('@/assets/images/Footer/privacyoptions.svg?external'),
          button: true,
        },
        {
          name: 'Terms of use',
          route: 'terms',
        },
        {
          name: 'Social Media House Rules',
          route: 'social-media-house-rules',
        },
      ];
    },
    socialMediaLinks() {
      return [
        {
          src: require('@/assets/images/Footer/facebook_white.png'),
          route: 'https://www.facebook.com/ElectrifyAmerica/',
          alt: 'Facebook',
        },
        {
          src: require('@/assets/images/Footer/instagram_white.png'),
          route: 'https://www.instagram.com/electrifyamerica/?hl=en',
          alt: 'Instagram',
        },
        {
          src: require('@/assets/images/Footer/x_white.png'),
          route: 'https://x.com/electrifyam?lang=en',
          alt: 'X',
        },
        {
          src: require('@/assets/images/Footer/linkedin_white.png'),
          route: 'https://www.linkedin.com/company/electrifyamerica/about/',
          alt: 'Linked In',
        },
        {
          src: require('@/assets/images/Footer/youtube_white.png'),
          linkTo: 'https://www.youtube.com/channel/UC3GGcyuzYYyYmZ0hJKlBb6w?view_as=subscriber',
          alt: 'Youtube',
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/base.scss';

#app {
  font-family: 'Ubuntu', 'Omnes', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #011352;
  font-weight: 300;
  letter-spacing: 0.02em;
  line-height: 1.5;

  strong {
    font-weight: 600;
  }
}

/* Text meant only for screen readers. */
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
.skip-link {
  background: #fff;
  color: #011352;
  font-weight: 700;
  top: 5px;
  left: 5px;
  padding: 4px;
  position: absolute;
  transform: translateY(50%);
  transition: transform 0.3s;

  &:focus,
  &:active {
    outline-color: #011352;
    z-index: 999;
    transform: translateY(0%);
  }
}

// DEFAULTS
main {
  background-color: #ffffff;
}

.main-wrap {
  display: flex;
  background-color: #f5f5f5;
  flex-direction: column;
  font-weight: 400;
  position: relative;
  @media only screen and (max-width: 750px) {
    padding: 0px !important;
  }

  .fixed-button {
    border-radius: 8px 0 0 8px;
    right: 0;
    z-index: 2;
    top: 150px;
    position: fixed;
  }
}

.hero-section-global {
  img {
    width: 100%;
    display: block;
  }

  .content {
    .header {
      font-size: 36px;
      @media only screen and (min-width: 750px) {
        font-size: 50px;
      }
    }

    .detail {
      text-align: center;
    }

    .contact-us {
      text-decoration: underline;
      font-weight: 600;
      margin-top: 0;
    }
  }

  .line-break {
    align-self: center !important;
  }

  .flex-column {
    align-items: stretch !important;
  }

  .contact-us {
    align-self: center !important;
  }
}

.two-column-section {
  background-color: #f8f8f8;

  .sub-header {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }

  .close {
    margin-top: 16px;
  }

  .two-column-wrap {
    box-sizing: border-box;

    .list {
      margin-top: 16px;
    }

    .list-style {
      list-style-position: outside;
      margin-left: 22px;
      margin-bottom: 8px;
    }

    .content {
      justify-content: flex-start;
      position: relative;
      @media only screen and (min-width: 750px) {
        margin-top: 60px;
      }
    }
    .image {
      img {
        width: 100%;
        height: 100%;
        @media only screen and (min-width: 750px) {
          max-height: 424px;
          height: auto;
        }
      }
    }
  }
  .two-column-wrap:nth-child(even) {
    background-color: white;
    .image {
      @media only screen and (min-width: 750px) {
        order: 1;
      }
    }

    .content {
      @media only screen and (min-width: 750px) {
        margin-left: 60px;
        margin-right: 0;
        order: 2;
      }
    }
  }
}

.contact-section {
  .detail {
    text-align: center;
  }

  .line-break {
    align-self: center !important;
  }

  .line {
    align-self: center !important;
  }

  .detail {
    margin: 0 auto;
  }

  .flex-column {
    align-items: stretch !important;
  }

  .button {
    align-self: center;
  }
}

.image-gallery-global {
  background-color: #f8f8f8;
  padding: 60px 0;
  @media only screen and (min-width: 750px) {
    padding: 100px 0;
  }

  .container {
    max-width: 1000px;
  }
  img {
    width: 100%;
    display: block;
  }
  .imgage-wrap {
    display: flex;
    flex-direction: column;
    max-width: 950px;
  }

  .two-image-row {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 750px) {
      margin-top: 32px;
      flex-direction: row;
    }

    .img-left,
    .img-right {
      margin-bottom: 32px;
      flex: 1;
    }

    .img-left {
      margin-right: 0;
      @media only screen and (min-width: 750px) {
        margin-right: 32px;
      }
    }
  }
}

.contact-feature {
  background-color: white;

  .detail {
    align-self: center;
    margin-top: 30px;
    max-width: 425px;
  }
}

.hero-section-global,
.contact-section {
  .content {
    align-items: center;
    padding: 40px 0;
    width: 100%;
    @media only screen and (min-width: 750px) {
      padding: 100px 0;
      max-width: 750px;
      margin: 0 auto;
    }
  }
}

.line-break {
  border: none;
  border-radius: 4px;
  margin: 20px 0;
  width: 38px;
  height: 4px;
  background-color: #87cc97;
  @media only screen and (min-width: 750px) {
    margin: 30px 0;
    width: 77px;
  }
}

.video {
  width: 100%;
  height: auto;
  object-fit: fill;
}

h2 {
  margin: 0;
  padding: 0;
}

.container {
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1287px;
  height: 100%;
  @media only screen and (min-width: 750px) {
    padding: 0 40px;
  }
}

.link-to:hover {
  color: #35bdd1;
}

// Main default for Headers
main .header {
  text-align: center;
  color: #011352;
  font-size: 28px;
  line-height: 36px;
  font-family: 'Ubuntu', 'Omnes', Arial, Helvetica, sans-serif;
  font-weight: 600;
  @media only screen and (min-width: 750px) {
    font-size: 56px;
    letter-spacing: 0.02em;
    line-height: 71px;
  }
}
h1 {
  text-align: center;
  color: #011352;
  font-size: 28px;
  line-height: 36px;
  font-family: 'Ubuntu', 'Omnes', Arial, Helvetica, sans-serif;
  font-weight: 300;
  margin: 0;
  @media only screen and (min-width: 750px) {
    font-size: 55px;
    letter-spacing: 0.02em;
    line-height: 71px;
  }

  &.bold {
    font-weight: 700;
  }
  &.heavy {
    font-weight: 900;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 5.26%, rgba(0, 0, 0, 0.8) 71.78%, rgba(0, 0, 0, 0.9) 100%);
  transform: rotate(-180deg);
  top: 0;
  opacity: 0.6;
  @media only screen and (max-width: 750px) {
    border-radius: 0;
  }
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.two-column-wrap {
  padding: 40px 0;
  @media only screen and (min-width: 750px) {
    padding: 100px 0;
  }

  .image,
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .image {
    order: 1;
    @media only screen and (min-width: 750px) {
      order: 2;
    }
  }

  .content {
    justify-content: center;
    order: 2;
    @media only screen and (min-width: 750px) {
      margin-right: 60px;
      order: 1;
    }
  }

  .header {
    text-align: left;
    margin: 32px 0 16px;
    @media only screen and (min-width: 750px) {
      font-size: 50px;
      line-height: 64px;
      margin: 0 0 16px;
    }
  }
  .detail {
    line-height: 24px;
    margin-bottom: 32px;
    @media only screen and (min-width: 750px) {
      width: 80%;
      margin: 0;
    }
  }

  .expand-detail {
    cursor: pointer;
    margin-top: 16px;
    font-weight: 500;
    color: #011352;
  }
}

a {
  color: #011352;
  text-decoration: none;
}

.inline-button {
  display: inline-block !important;
  max-width: 130px;
  text-align: center;
  border-radius: 5px;
}

.button {
  display: inline-block;
  text-decoration: none !important;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(53, 189, 209, 1) 50%, rgba(1, 19, 82, 1) 50%);
  background-position: 100% 0;
  background-size: 200% auto;

  &.light {
    background: linear-gradient(90deg, rgba(53, 189, 209, 1) 50%, rgba(0, 111, 128, 1) 50%);
    background-position: 100% 0;
    background-size: 200% auto;
  }
  &.white {
    background: linear-gradient(90deg, #0a124a 50%, white 50%);
    background-position: 100% 0;
    background-size: 200% auto;
    color: #0a124a;
  }

  margin-top: 40px;
  padding: 1.28em 1.6em;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  text-decoration: initial;
  text-transform: uppercase;
  transition: background-position 0.3s ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &.button-link {
    background: transparent;
    color: $c-primary;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 1.5rem;
    margin: 0;
    border: none;
    text-decoration: underline !important;
    text-align: left;

    &[href*='https://']::after,
    &[href*='http://']::after
    {
      content: '';
      position: relative;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjYyNSIgaGVpZ2h0PSI5LjYyNSIgdmlld0JveD0iMCAwIDkuNjI1IDkuNjI1Ij4KICA8cGF0aCBpZD0iUGF0aF8xOCIgZGF0YS1uYW1lPSJQYXRoIDE4IiBkPSJNMS4wMzEtOC4yNWEuMzQ0LjM0NCwwLDAsMS0uMzQ0LS4zNDQuMzQ0LjM0NCwwLDAsMSwuMzQ0LS4zNDRINC40NjlhLjM0NC4zNDQsMCwwLDEsLjM0NC4zNDR2My40MzhhLjM0NS4zNDUsMCwwLDEtLjM0NC4zNDQuMzQ1LjM0NSwwLDAsMS0uMzQ0LS4zNDRWLTcuNzY0TC0uNzg4LTIuODUxYS4zNDEuMzQxLDAsMCwxLS40ODYsMCwuMzQxLjM0MSwwLDAsMSwwLS40ODZMMy42MzktOC4yNVpNLTQuODEyLTcuMjE5QTEuMDMxLDEuMDMxLDAsMCwxLTMuNzgxLTguMjVoMi43NWEuMzQ0LjM0NCwwLDAsMSwuMzQ0LjM0NC4zNDQuMzQ0LDAsMCwxLS4zNDQuMzQ0aC0yLjc1YS4zNDQuMzQ0LDAsMCwwLS4zNDQuMzQ0Vi0uMzQ0QS4zNDQuMzQ0LDAsMCwwLTMuNzgxLDBIMy4wOTRhLjM0NS4zNDUsMCwwLDAsLjM0NC0uMzQ0di0yLjc1YS4zNDUuMzQ1LDAsMCwxLC4zNDQtLjM0NC4zNDUuMzQ1LDAsMCwxLC4zNDQuMzQ0djIuNzVBMS4wMzIsMS4wMzIsMCwwLDEsMy4wOTQuNjg4SC0zLjc4MUExLjAzMSwxLjAzMSwwLDAsMS00LjgxMi0uMzQ0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC44MTMgOC45MzgpIiBmaWxsPSIjMDExMzUyIi8+Cjwvc3ZnPgo=');
      height: 10px;
      width: 10px;
      display: inline-block;
      background-repeat: no-repeat;
      margin-left: 0.25rem;
      margin-right: 0.15rem;
      background-size: 100%;
    }

    &.text-transform-none {
      text-transform: none;
    }

    &:hover {
      color: $c-focus;
      .arrow {
        path {
          fill: $c-focus;
        }
      }
    }
  }

  &:hover {
    @media only screen and (max-width: 1130px) {
      background-position: 100% 0 !important;
    }
    background-position: 0 0;
    color: #0a124a;
  }

  &.white:hover {
    color: white;
  }

  @media (max-width: 750px) {
    width: 100%;
    box-sizing: border-box;
    padding: 11px 0;
    text-align: center;
    border-radius: 30px;
    font-size: 18px;
  }
}

.box {
  width: fit-content;
  margin: auto;
  &.big {
    p,
    img,
    .text {
      max-width: 310px;
    }
  }
  &:not(.big) {
    p,
    img,
    .text {
      max-width: 300px;
    }
  }
  &:not(:first-child) {
    margin-top: 20px;
  }
  img {
    width: 100%;
    display: block;
  }
  p,
  .text {
    &:not(.white) {
      background-color: #ebebeb;
    }
    &.white {
      background-color: #ffffff;
    }
    width: 100%;
    margin: 0 !important;
    padding: 20px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
  }
}

select {
  display: block;
  &[multiple] {
    height: 70px;
  }
  &:not([multiple]) {
    height: 35px;
  }
  width: 278px;
  max-width: 100%;
  margin-bottom: 30px;
  border-radius: 0;
  background-color: #ffffff;
  cursor: pointer;
  &,
  option {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Ubuntu', 'Omnes', Arial, sans-serif;
    color: #8089a8;
    padding: 0px 2px 1px;
  }
}

form {
  color: #8089a8;
  label {
    font-size: 12px;
    font-weight: 500;
    vertical-align: top;
  }
  input,
  select,
  textarea {
    box-sizing: border-box;
  }
  select {
    display: block;
    &[multiple] {
      height: 70px;
    }
    &:not([multiple]) {
      height: 42px;
    }
    width: 100%;
    max-width: 100%;
    margin-bottom: 36px;
    border: 1px solid #707070;
    border-radius: 8px;
    background-color: #ffffff;
    cursor: pointer;
    &,
    option {
      font-size: 16px;
      font-weight: 400;
      font-family: 'Ubuntu', 'Omnes', Arial, sans-serif;
      color: #8089a8;
      padding: 0px 2px 1px;
    }
  }
  input[type='text'],
  input[type='number'],
  textarea {
    display: block;
    font-size: 16px;
    line-height: normal;
    color: #011352;
    height: 42px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 36px;
    box-shadow: inset 0 -1px 0 0 #bfc4d4;
    border: 1px solid #707070;
    -webkit-appearance: none;
  }
  input[type='text'] {
    background-color: transparent;
    border: none;
  }
  input[type='checkbox'] {
    display: inline-block;
    vertical-align: text-top;
    cursor: pointer;
    box-shadow: none;
    margin: 0 20px 30px 0;
    width: 16px;
    height: 16px;
  }
  .button {
    border-width: 0;
  }

  input:disabled,
  input[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.5;

    &.button:hover {
      background-position: 100% 0 !important;
      color: white;
    }
  }
}

#main {
  overflow: hidden;
  position: relative;
  min-height: 650px;
}

article {
  min-height: 650px;
  .twosided {
    padding-top: 70px;
    .left,
    .right {
      h2 {
        font-size: 40px;
        line-height: 1.2;
        font-weight: 600;
        margin: 0 0 20px 0;
      }
      p {
        margin: 20px 0;
        line-height: 1.5;
        .navigate {
          color: inherit;
          font-weight: 400;
          white-space: nowrap;
          text-decoration: underline;
        }
      }
    }
  }
  .article-body {
    padding-bottom: 80px;
    background-color: #ebebeb;
    &.text {
      background-color: #ffffff;
      padding: 0 0 140px 0;
      .container {
        .dropdown-container {
          p {
            margin: 0 0 0 30px !important;
            padding: 0 0 20px;
          }
          border-bottom: 1px solid rgba(1, 19, 82, 0.5);
          overflow: hidden;
          cursor: pointer;
          h2 {
            margin: 14px 0;
            padding-left: 30px;
            &.open::before {
              content: '-';
            }
            &.closed::before {
              content: '+';
            }
            &::before {
              position: relative;
              float: left;
              margin-left: -30px;
              width: 30px;
              padding-bottom: 14px;
            }
          }
          .slide-enter-active,
          .slide-leave-active {
            transition: height 0.3s ease-in-out;
          }
          .slide-enter,
          .slide-leave-to {
            height: 0;
          }
        }
        max-width: 1070px;
        h1 {
          margin: 0 0 28px;
        }
        h2 {
          font-size: 24px;
          line-height: 1.5;
          margin: 40px 0 20px;
        }
        p {
          strong {
            font-weight: 600;
          }
        }
      }
    }
    .container {
      max-width: 768px;
      margin: auto;
      ul,
      ol {
        font-weight: 300;
        line-height: 1.5;
      }
      h2 {
        margin: 0 0 20px 0;
        font-size: 1em;
        font-weight: 600;
        &:not(.top) {
          margin-top: 40px;
        }
      }
      p {
        margin: 20px 0;
        line-height: 1.5;
        .navigate {
          color: inherit;
          font-weight: 400;
          text-decoration: underline;
        }
      }
    }
    .big-container {
      .header {
        margin: 0 0 20px 0;
      }
      h2 {
        font-size: 40px;
        line-height: 1.2;
        font-weight: 600;
        margin: -10px 0 20px 0;
      }
      p:not(.header),
      .inline {
        line-height: 1.5;
        .navigate {
          color: inherit;
          font-weight: 400;
          text-decoration: underline;
        }
        strong {
          font-weight: 600;
        }
      }
    }
  }
  .img-container {
    max-width: 1160px;
    margin: 0 auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  p {
    font-weight: 300;
  }
  h1 {
    font-weight: 100;
    line-height: 0.875em;
    margin-block-start: 0;
    max-width: 1160px;
    margin: 0 auto;
  }
  .desc {
    line-height: 1.5;
    margin: 0 auto;
    max-width: 1160px;
  }
  &.mobile {
    padding-top: 100px !important;
    .twosided {
      margin: 0 30px;
      .right h2 {
        padding-top: 70px;
      }
      .pic {
        &:not(.tiny) {
          width: 60%;
          margin: auto;
        }
        .tiny {
          margin: 0 30px;
        }
      }
    }
    .article-body {
      &:not(.text) {
        padding-top: 80px;
      }
      .container {
        max-width: none !important;
        margin: 0 30px;
      }
      .big-container {
        margin: 0 30px;
        p:not(.header),
        .inline {
          margin: 20px 0;
        }
      }
    }
    h1 {
      font-size: 40px;
      margin: 0 30px 14px;
    }
    .desc {
      font-size: 16px;
      padding-left: 30px;
    }
    .img-container:not(.regmargin) {
      margin: 40px 30px -40px 30px;
    }
  }
  &:not(.mobile) {
    padding-top: 100px;
    .twosided {
      display: flex;
      justify-content: space-between;
      max-width: 1070px;
      margin: auto;
      .left,
      .right {
        width: 40%;
      }
    }
    .article-body {
      &:not(.text) {
        padding-top: 235px;
      }
      .big-container {
        max-width: 1070px;
        margin: auto;
        h2 {
          float: left;
          width: 32%;
        }
        p:not(.header),
        .inline {
          margin: 20px 0 20px 40%;
        }
      }
    }
    h1 {
      font-size: 80px;
      margin-bottom: 28px;
    }
    .desc {
      font-size: 18px;
    }
    .img-container:not(.regmargin) {
      margin: 40px auto -140px;
    }
  }
}

html,
body {
  margin: 0;
  overflow-x: hidden;
}
</style>
